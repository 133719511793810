import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Image } from "@nextui-org/react";
import React, { useState } from 'react'
import Comment from "./Comment";

const ModalComponent = ({ isOpen, onClose, whiskey }) => {
  const [backdrop, setBackdrop] = useState('opaque')

  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    if (newRating >= 1 && newRating <= 5) {
      setRating(newRating);
    }
  };



  return (
    <>

      <Modal backdrop={backdrop} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 dark:text-white">{whiskey.name}</ModalHeader>
              <ModalBody className="">
                <img
                  alt="Card background"
                  className="object-contain max-w-full max-h-80 mx-auto rounded-xl"
                  src={whiskey.imgUrl}
                />

                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam pulvinar risus non risus hendrerit venenatis.
                  Pellentesque sit amet hendrerit risus, sed porttitor quam.
                </p> */}
                <p className="dark:text-white">
                  {whiskey.description}
                </p>
                <h2 className="mt-8 dark:text-white">Betygsätt</h2>
                <div className='flex gap-6 dark:text-white'>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      className={`rating-star${value <= rating ? ' active' : ''}`}
                      onClick={() => handleRatingChange(value)}
                    >
                      {rating !== 0 ? value <= rating ? <i className="fa-solid fa-star fa-2xl cursor-pointer" style={{ color: '#0300c2' }} ></i> : <i className="fa-regular fa-star fa-2xl cursor-pointer" style={{ color: '#0300c2' }}></i> : value <= whiskey.rating ? <i className="fa-solid fa-star fa-2xl cursor-pointer" ></i> : <i className="fa-regular fa-star fa-2xl cursor-pointer"></i>}
                    </span>
                  ))}
                </div>

                {/* <div className="mt-4">
                  <h4 className="dark:text-white">Kommentarer</h4>
                  <div className="flex gap-3 flex-col mt-2">
                    <Comment />
                    <Comment />
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                      About
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 px-1.5 dark:text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder='Skriv en kommentar..'
                      />
                    </div>
                    <button
                      type="submit"
                      className="rounded-md mt-2 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Skicka
                    </button>
                  </div>
                </div> */}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Stäng
                </Button>

              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalComponent