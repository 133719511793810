import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { NextUIProvider } from '@nextui-org/react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { register } from 'swiper/element/bundle';
import { BrowserRouter } from 'react-router-dom';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdby4sw7dKuQ_v4a-yfHOdH-0RMySLlqg",
  authDomain: "whiskeykalendern-508be.firebaseapp.com",
  projectId: "whiskeykalendern-508be",
  storageBucket: "whiskeykalendern-508be.appspot.com",
  messagingSenderId: "1004286518652",
  appId: "1:1004286518652:web:ea2bfa111f8166da7730f9",
  measurementId: "G-55DYVHZRYZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const analytics = getAnalytics(app);

// import function to register Swiper custom elements
// register Swiper custom elements
register();






const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <NextUIProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </NextUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

export { db }