import React, { useContext } from 'react'
import CardComponent from '../components/Card'
import { Link } from 'react-router-dom'
import SwiperComponent from '../components/SwiperComponent'
import { WhiskeyContext } from '../App'


const StartView = () => {
    
    const whiskeys = useContext(WhiskeyContext)


    return (
        <div className='container'>
            {/* <ModalComponent /> */}
            <div className=''>
                <SwiperComponent whiskeys={whiskeys} />
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 w-full px-5'>

                {whiskeys.map(w => <CardComponent key={w.id} whiskey={w} />)}
            </div>
            <div className='mt-10'>
                <Link to="/login" className='hover:underline text-xl text-center'><p>Gå till loginsidan</p></Link>

            </div>

            {/* <CardComponent /> */}
            {/* <CardComponent /> */}
        </div>
    )
}

export default StartView