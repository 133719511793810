import React, { useState } from 'react'
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import createNewUser from '../firebase/createNewUser';
import { useNavigate } from 'react-router-dom';


const Registration = ({ setLogin }) => {
  const auth = getAuth();

  const [newUser, setNewUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: ''
  })

  const [errors, setErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: '',
  })

  const [created, setCreated] = useState(false)

  const navigate = useNavigate()


  const validateEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let newErrors = { ...errors };

    // Validate email
    if (!validateEmail(newUser.email)) {
      newErrors.email = 'Felaktig epost';
    } else {
      newErrors.email = '';
    }

    // Validate first name
    if (newUser.firstName.trim() === '') {
      newErrors.firstName = 'Förnamn är obligatoriskt';
    } else {
      newErrors.firstName = '';
    }

    // Validate last name
    if (newUser.lastName.trim() === '') {
      newErrors.lastName = 'Efternamn är obligatoriskt';
    } else {
      newErrors.lastName = '';
    }

    // Validate password
    if (newUser.password.length < 8) {
      newErrors.password = 'Lösenordet måste vara minst 8 tecken långt';
    } else {
      newErrors.password = '';
    }

    // Validate repeat password
    if (newUser.password !== newUser.repeatPassword) {
      newErrors.repeatPassword = 'Lösenorden matchar inte';
    } else {
      newErrors.repeatPassword = '';
    }

    // Update errors state
    setErrors(newErrors);

    // If there are no errors, you can proceed with form submission
    if (
      !newErrors.email &&
      !newErrors.firstName &&
      !newErrors.lastName &&
      !newErrors.password &&
      !newErrors.repeatPassword
    ) {
      // Perform form submission or other actions here
      const res = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password)
      console.log(res)
      if(res._tokenResponse.idToken) {
        const _res = await createNewUser(newUser.firstName, newUser.lastName, res.user.uid)
        console.log(_res)
        updateProfile(auth.currentUser, {
          displayName: newUser.firstName
        })
        .then(() => {

          setNewUser({
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            repeatPassword: '',
          })
          navigate("/")
          setCreated(true)
        })
        .catch((error) => console.log(error))
      }

    }
  };

  const handleOnChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" /> */}
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white text-gray-900">Registrera konto</h2>
        {created && <p className='text-success text-xl text-center'>Kontot skapades utan några problem!</p>}
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">Epost</label>
            <div className="mt-2">
              <input value={newUser.email} onChange={handleOnChange} id="email" name="email" type="email" autoComplete="email" required className="block w-full rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              {errors.email && <p className="text-danger">{errors.email}</p>}
            </div>
          </div>
          <div className='flex w-full justify-between'>
            <div>

              <label htmlFor="firstName" className="inline-block text-sm font-medium leading-6 dark:text-white text-gray-900">Förnamn</label>
              <div className="mt-2">
                <input value={newUser.firstName} onChange={handleOnChange} id="firstName" name="firstName" type="text" autoComplete="firstName" required className="inline-block w-full rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
              </div>
            </div>
            <div>

              <label htmlFor="lastName" className="inline-block text-sm font-medium leading-6 dark:text-white text-gray-900">Efternamn</label>
              <div className="mt-2">
                <input value={newUser.lastName} onChange={handleOnChange} id="lastName" name="lastName" type="text" autoComplete="lastName" required className="inline-block w-full rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">Lösenord</label>
            </div>
            <div className="mt-2">
              <input value={newUser.password} onChange={handleOnChange} id="password" name="password" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              {errors.password && <p className="text-danger">{errors.password}</p>}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="repeatPassword" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">Repetera Lösenord</label>
            </div>
            <div className="mt-2">
              <input value={newUser.repeatPassword} onChange={handleOnChange} id="repeatPassword" name="repeatPassword" type="password" required className="block w-full rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              {errors.repeatPassword && <p className="text-danger">{errors.repeatPassword}</p>}
            </div>
          </div>

          <div>
            <button type="submit" className="flex w-full justify-center border rounded-md text-sky-400 bg-indigo-600 dark:bg-indigo-950 dark:border-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 mt-10 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Registrera</button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500 hover:underline cursor-pointer" onClick={() => setLogin(true)}>
          Har du redan ett konto?
        </p>
      </div>
    </div>
  )
}

export default Registration