import { Route, Routes  } from 'react-router-dom';
import './index.css';

import Navbar from './components/Navbar';
import StartView from './views/StartView';
import LoginView from './views/LoginView';
import { createContext, useState } from 'react';


export const WhiskeyContext = createContext()


function App() {
  const [whiskeys, setWhiskeys] = useState([
    {
      id: 1,
      date: "1",
      name: "Nikka",
      country: 'Japan',
      description: 'Nikka Taketsuru Pure Malt är en blended malt, eller vatted malt som det också kallas, och innehåller enbart maltwhisky. Till skillnad från single malt som kommer från ett destilleri, så kommer whiskyn i blended malt från flera olika destillerier. Destilleringen skedde i enkelpannor av koppar.',
      smokey: 'Nej',
      imgUrl: 'https://product-cdn.systembolaget.se/productimages/24625382/24625382_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/nikka-4106101/',
      rating: 4,
      countryImg: './assets/japan.png'
    },
    {
      id: 2,
      date: "2",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5,
      countryImg: './assets/mexico.png'
    },
    {
      id: 3,
      date: "3",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 4,
      date: "4",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 5,
      date: "5",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 6,
      date: "6",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 7,
      date: "7",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 8,
      date: "8",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 9,
      date: "9",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 10,
      date: "10",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 11,
      date: "11",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 12,
      date: "12",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 13,
      date: "13",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 14,
      date: "14",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 15,
      date: "15",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 16,
      date: "16",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 17,
      date: "17",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 18,
      date: "18",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 19,
      date: "19",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 20,
      date: "20",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 21,
      date: "21",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 22,
      date: "22",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 23,
      date: "23",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
    {
      id: 24,
      date: "24",
      name: "Abasolo",
      country: 'Mexico',
      description: 'Drycken finns i lager hos leverantör, inte hos Systembolaget. Den är inte provad av Systembolaget och därför visas ingen smakbeskrivning. Drycken kan finnas i butiker vid lokal efterfrågan.',
      smokey: 'Nej',
      imgUrl: 'https://sb-product-media-prod.azureedge.net/productimages/24513486/24513486_400.png?q=75&w=2000',
      url: 'https://www.systembolaget.se/produkt/sprit/abasolo-8431701/',
      rating: 5
    },
  ])
  return (
    <div className="App bg-white dark:bg-black pb-28">
      <WhiskeyContext.Provider value={whiskeys}>

        <Navbar />
        <header className="App-header container mx-auto">
          <h1 className='text-4xl text-center my-10 dark:text-white text-black'>Whiskeykalendern</h1>
        </header>

        <Routes>
          <Route path='/' element={<StartView />} />
          <Route path='/login' element={<LoginView />} />
        </Routes>
      </WhiskeyContext.Provider>

    </div>
  );
}

export default App;
