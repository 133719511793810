import React, { useState } from 'react'
import Login from '../components/Login'
import Registration from '../components/Registration'
import { Link } from 'react-router-dom'

const LoginView = () => {
    const [login, setLogin] = useState(true)
    return (
        <div className='container'>
            <Link to="/" ><div className='back-arrow border ms-6 p-5 flex justify-center items-center bg-indigo-600 text-sky-400 dark:bg-indigo-950 dark:border-slate-800 hover:bg-indigo-500'>
                <i className="fa-solid fa-arrow-left"></i>
            </div></Link>
            {login ? <Login setLogin={setLogin} /> : <Registration setLogin={setLogin} />}
        </div>
    )
}

export default LoginView