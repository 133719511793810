import React, { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, NightIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

    const [loggedIn, setLoggedIn] = useState(false)

    const [navigation, setNavigation] = useState([
        { name: 'Start', href: '/', current: true },
        { name: 'Logga in', href: '/login', current: false },
    ])
    const [darkMode, setDarkMode] = useState(true)
    const b = document.querySelector('body')

    const handleDarkMode = () => {
        setDarkMode(state => !state)
        b.classList.toggle('dark')
    }

    const handleNavigation = (item) => {
        const newNavState = navigation.map(nav => {
            if (nav === item) {
                nav.current = true
                return nav
            }
            nav.current = false
            return nav
        })
        console.log(newNavState)
        setNavigation(newNavState)
    }

    const handleLogout = () => {
        const auth = getAuth()
        signOut(auth)
    }

    useEffect(() => {
        const handleAuthState = () => {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    console.log(user)
                    setLoggedIn(true)
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/auth.user
                    // const uid = user.uid;
                    // ...
                } else {
                    setLoggedIn(false)
                    // User is signed out
                    // ...
                }
            });
        }
        handleAuthState()


    }, [])






    return (
        <Disclosure as="nav" className="dark:bg-gray-800 bg-white-800">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                        alt="Your Company"
                                    />
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => {
                                            if (item.name === 'Logga in') {
                                                if (loggedIn) return

                                                return (<NavLink
                                                    onClick={(e) => handleNavigation(item)}
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current ? 'dark:bg-gray-900 dark:text-white bg-zinc-300 text-black hover:bg-gray-500' : 'dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-500 hover:text-black',
                                                        'rounded-md px-3 py-2 text-sm font-medium'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </NavLink>)
                                            }
                                            else {
                                                return (<NavLink
                                                    onClick={(e) => handleNavigation(item)}
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current ? 'dark:bg-gray-900 dark:text-white bg-zinc-300 text-black hover:bg-gray-500' : 'dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-500 hover:text-black',
                                                        'rounded-md px-3 py-2 text-sm font-medium'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </NavLink>)
                                        }
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <button
                                    onClick={handleDarkMode}
                                    type="button"
                                    className="relative rounded-full h-8 w-8 dark:bg-zinc-300 bg-gray-800 dark:text-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">View notifications</span>
                                    {/* <NightIcon className="h-6 w-6" aria-hidden="true" /> */}
                                    {!darkMode ? <i className="fa-regular fa-moon h-6 w-6"></i> : <i className="fa-solid fa-sun"></i>}

                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="relative flex rounded-full items-center justify-center bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            {/* <span className="absolute -inset-1.5" /> */}
                                            {/* <span className="sr-only">Open user menu</span> */}
                                            <div className='h-8 w-8 flex justify-center items-center'>
                                                {loggedIn ? <i className="fa-solid fa-user text-white"></i> : <i className="fa-solid fa-lock text-white"></i>}
                                            </div>
                                            {/* <img
                                                className="h-8 w-8 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            /> */}
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 dark:text-white  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="/"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm dark:text-white text-gray-700')}
                                                    >
                                                        Din Profil
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <span
                                                        onClick={handleLogout}
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm dark:text-white text-gray-700 cursor-pointer')}
                                                    >
                                                        Logga ut
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>

                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <NavLink
                                    onClick={(e) => handleNavigation(item)}
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                        item.current ? 'dark:bg-gray-900 dark:text-white bg-zinc-300 block text-black hover:bg-gray-500' : 'dark:text-gray-300 block dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-500 hover:text-black',
                                        'rounded-md px-3 py-2 text-sm font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </NavLink>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
