import React from 'react'

const SlideCard = ({children}) => {

    const handleSwipe = e => {
        console.log(e)
    }

  return (
    <div onTouchStart={handleSwipe} className='absolute'>{children}</div>
  )
}

export default SlideCard