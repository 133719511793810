import React, { useEffect, useRef } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import CardComponent from './Card';
import SlideCard from './SlideCard';

const SwiperComponent = ({ whiskeys }) => {

    const swiperElRef = useRef(null)


    useEffect(() => {
        // listen for Swiper events using addEventListener
        swiperElRef.current.addEventListener('progress', (e) => {
          const [swiper, progress] = e.detail;
        //   console.log(e)
        //   console.log(progress);
    });
    
    swiperElRef.current.addEventListener('slidechange', (e) => {
        console.log(e);
    });
    swiperElRef.current.addEventListener('click', e => {
        //   console.log(e);
        })
      }, []);


    return (
        <>
            <swiper-container ref={swiperElRef} class="my-swiper" centered-slides="true" slides-per-view="3" speed="500" loop="true"  space-between="8">

                {whiskeys.length > 0 && whiskeys.map((w, index) => <swiper-slide key={index}><CardComponent whiskey={w} /></swiper-slide>)}
            </swiper-container>
            {/* <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 w-full px-5'>

                {whiskeys.map(w => <CardComponent key={w.id} whiskey={w} />)}
            </div> */}
        </>
    )
}

export default SwiperComponent