import { doc, addDoc, collection, setDoc } from "firebase/firestore";
import { db } from "..";

// Add a new document in collection "cities"
const createNewUser = async (_firstName, _lastName, _userId) => {
  console.log(db)
  const userRef = doc(collection(db, "userInfo"))
  const data = {
    firstName: _firstName,
    lastName: _lastName,
    userId: _userId
  }
  await setDoc(userRef, data);
}

export default createNewUser