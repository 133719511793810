import React from 'react'
import { Card, CardHeader, CardBody, Image, useDisclosure } from "@nextui-org/react";
import ModalComponent from './ModalComponent';

const CardComponent = ({ whiskey }) => {

    // const [rating, setRating] = useState(0);
    const currentDate = 2
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleOpen = (e) => {
        onOpen();
    }


    // const handleRatingChange = (newRating) => {
    //     if (newRating >= 1 && newRating <= 5) {
    //         setRating(newRating);
    //     }
    // };

    return (
        <div onClick={handleOpen} className=' w-full'>

            <Card className="position-relative py-4 w-full cursor-pointer hover:bg-zinc-100 dark:hover:bg-slate-800  card-size" >
                <p style={{backgroundImage: `url(${whiskey.countryImg}) `}} className={`background-number ${currentDate < whiskey.date ? 'text-zinc-950 dark:text-neutral-200 opacity-50' : 'text-transparent bg-clip-text'} ${whiskey.date > 9 && 'double-digit'}` }>{whiskey.date}</p>
                {currentDate >= whiskey.date && <><ModalComponent isOpen={isOpen} onClose={onClose} whiskey={whiskey} />
                    <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                        {/* <p className="text-tiny uppercase font-bold">{whiskey.date} December</p> */}
                        <small className="text-default-500">{whiskey.country}</small>
                        <h4 className="font-bold text-large">{whiskey.name}</h4>
                    </CardHeader>
                    <CardBody className="overflow-visible py-2 gap-2">
                        <Image
                            alt="Card background"
                            className="object-contain h-48 rounded-xl"
                            src={whiskey.imgUrl}
                            width={270}
                        />



                        <h2>Betyg</h2>
                        <div className='flex gap-1 md:gap-3'>
                            {[1, 2, 3, 4, 5].map((value) => (
                                <span
                                    key={value}
                                    className={`rating-star`}
                                // onClick={() => handleRatingChange(value)}
                                >
                                    {value <= whiskey.rating ? <i className="fa-solid fa-star text-lg sm:text-2xl cursor-pointer" ></i> : <i className="fa-regular fa-star text-lg sm:text-2xl cursor-pointer"></i>}
                                </span>
                            ))}
                        </div>
                    </CardBody></>}

            </Card>
        </div>
    )
}

export default CardComponent