import React, { useState } from 'react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const Login = ({ setLogin }) => {

  const [user, setUser] = useState({
    email: '',
    password: ''
  })

  const handleOnChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    const auth = getAuth();
    signInWithEmailAndPassword(auth, user.email, user.password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user)
        if(user.accessToken) {
          setUser({
            email: '',
            password: ''
          })
          navigate('/')
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode)
        console.log(errorMessage)
      });
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" /> */}
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white text-gray-900">Logga in</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Epost</label>
            <div className="mt-2">
              <input value={user.email} onChange={handleOnChange} id="email" name="email" type="email" autoComplete="email" required className="block w-full dark:border-slate-800 rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Lösenord</label>
              <div className="text-sm">
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">Glömt bort ditt lösenord?*död länk*</a>
              </div>
            </div>
            <div className="mt-2">
              <input value={user.password} onChange={handleOnChange} id="password" name="password" type="password" autoComplete="current-password" required className="block w-full dark:border-slate-800 rounded-md border-0 p-1.5 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <button type="submit" className="flex w-full justify-center border rounded-md text-sky-400 bg-indigo-600 dark:bg-indigo-950 dark:border-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 mt-10 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Logga in</button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500 cursor-pointer hover:underline" onClick={() => setLogin(false)}>
          Har du inget konto?
        </p>
      </div>
    </div>
  )
}

export default Login